import BtnDecoration from "@vendor/assets/images/btn_decoration.png";
import { PaletteMode } from "@mui/material";
import ThankYouImg from "@vendor/assets/images/thank-you.png";
import { createTheme } from "@mui/material/styles";
import { vendorColor } from "@vendor/utils/constants";

type ButtonColor = "default" | "black" | "white";
const applePayBtnStyle: ButtonColor | undefined = "white";
const googlePayBtnStyle: ButtonColor | undefined = "white";

export const colors = {
  primary: "#ffffff",
  primaryDark: "#040507",
  secondary: "#E12844",
  secondaryDisabled: "#DF828F",

  error: "#E12844",
  errorLight: "#DF828F",
  errorDark: "#5a101b",
  success: "#66DB9A",
  successLight: "#257456",
  successDark: "#59AA80",
  text: "#fff",
  textSecondary: "rgba(255,255,255,0.6)",
  textDisabled: "#737373",
  background: "#353536",
  lighterBackground: "rgba(0, 0, 0, 0.5)",
  activeSelected: "#737373",
  activeDisabled: "#bebebe",
  activeDisabledBackground: "#737373",
  paper: "#353536",
  border: "#ffffff",
  borderLight: "#ffffff",
  borderMedium: "#515153",
  borderDark: "#2C2F31",
  vendorColor,
  utilityGray: "#737373",
  warning: "#33312f",
  warningDark: "#f49639",
  warningBorder: "#4c4946",
  warningText: "#ccc4bc",
  highlightColor: "#3DE6D1",
  switchTrackColor: "#3ec18f",
  applePayBtnStyle,
  googlePayBtnStyle,
};

export const fonts = {
  titlesFont: "DINPro, Helvetica, Arial, sans-serif",
  textFont: "DINPro, Helvetica, Arial, sans-serif",
  buttonsFont: "'Chamelton 30 Blk 2', Helvetica, Arial, sans-serif",
};

export const vendorTheme = createTheme({
  typography: {
    fontFamily: fonts.textFont,
    body1: {
      fontSize: "1.8rem",
    },
    h1: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.8rem",
      fontWeight: 600,
    },
    h2: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontFamily: fonts.titlesFont,
      fontSize: "1.5rem",
      fontWeight: "600",
    },
    button: {
      fontFamily: fonts.titlesFont,
      fontSize: "17px",
    },
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    mode: "dark" as PaletteMode,
    primary: {
      main: colors.primary,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
      dark: colors.errorDark,
    },
    success: {
      main: colors.success,
      light: colors.successLight,
      dark: colors.successDark,
    },
    text: {
      primary: colors.text,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
    },
    background: {
      default: colors.background,
      paper: colors.paper,
    },
    action: {
      active: colors.primary,
      hover: colors.primaryDark,
      selected: colors.activeSelected,
      disabled: colors.activeDisabled,
      disabledBackground: colors.activeDisabledBackground,
    },
    divider: colors.borderLight,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        #layout > * {
          background-color: transparent;
        },
        input {
          font-size: 2rem;
        }
        .btn_background .MuiCircularProgress-root {
          left: 24px;
        }
        .payment-method-list .MuiPaper-root {
          background: ${colors.lighterBackground};
          border: 2px solid ${colors.borderLight} !important;
        }
        #bottom-sheet [data-rsbs-overlay] {
          background: ${colors.background};

          h3 {
            color: ${colors.highlightColor};
            max-width: 180px;
          }

          .black-btn {
            background: ${colors.text};
            color: ${colors.background};
          }

          .black-btn-outline {
            border: 2px solid ${colors.text} !important;
            color: ${colors.text};

            &:hover {
              background: transparent;
            }
          }

          [data-rsbs-content] {
            > * {
              color: ${colors.textSecondary};
            }


            div + div {
              padding: 0 32px;
            }
          }
        }
        .order-list {
          .MuiPaper-root {
            background-color: transparent;
            background-image: none;
            border-width: 0px !important;
            box-shadow: none !important;
            padding: 0;
          }

          div[role='listitem'] div:last-child {
            font-weight: bold;
          }
        },

        #summary-accordion {
          display: none;
        }

        .tab-live-feed, .tab-overdue {
          .tab-number {
            margin: 0 0 12px 0;
            
            strong {
              font-size: 3.4rem;
            },
            span {
              font-size: 1.4rem;
            }

            &:after {
              color: ${colors.highlightColor};
              content: 'Please check your items before paying';
              font-size: 1.4rem;
            }
          },

          .MuiAvatar-root {
            background: none !important;
            border: 2px solid #CDCCCD !important;
            font-size: 1.4rem;
            height: 37px !important;
            width: 37px !important;

            .MuiAvatar-root {
              background: #CDCCCD !important;
              height: 25px !important;
              width: 25px !important;
            }
          },

          #tip-selection {
            background: #000000;
            position: relative;
            
            &:before {
              background: #000000;
              border-radius: 10px 10px 0 0;
              content: ' ';
              height: 100%;
              left: -18px;
              width: calc(100% + 36px);
              position: absolute;
              z-index: 0;
            }

            > * {
              z-index: 1;
            }

            .MuiToggleButtonGroup-root {
              .tip-btn {
                background: #000000;
                border: 2px solid ${colors.text} !important;
              }
            }

            #select-your-tip {
              font-size: 1.4rem;
              margin-bottom: 0;
            }
          },

          #summary {
            background: #000000;
            position: relative;
            z-index: 1;

            &:before {
              background: #000000;
              content: ' ';
              height: calc(100% + 16px);
              left: -18px;
              width: calc(100% + 36px);
              position: absolute;
              top: -16px;
              z-index: -1;
            }

            > * {
              z-index: 1;

              div, span {
                font-size: 1.4rem !important;
              }
            }

            > * div {
              background: #000000;
              color: ${colors.text} !important;
            }
            
            .inner-accordion .MuiAccordionSummary-root,
            .inner-accordion .MuiAccordionDetails-root {
              background: #000000;
            }
          }

          .tab-total {
            background-color: #000000 !important;
            text-transform: uppercase;

            &:before {
              background-color: #000000 !important;
              left: -18px !important;
              width: calc(100% + 36px) !important;
            }
          }
          
          .btn-container {
            background: #000000;
            position: relative;
            z-index: 1;

            &:before {
              background: #000000;
              content: ' ';
              height: calc(100% + 16px);
              left: -18px;
              width: calc(100% + 36px);
              position: absolute;
              top: -16px;
              z-index: -1;
            }
          }
        },

        .tab-overdue {
          #summary {
            &:before {
              border-radius: 10px 10px 0 0;
              top: -12px;
            }
          }
        }

        footer {
          @media (min-width: 541px) {
            margin: 0 38px;
            overflow: hidden;
          }
        }

        .tab-footer.has-items, .overdue-footer {
          background: #000000;
          position: relative;
          z-index: 1;

          &:before {
            background: #000000;
            content: ' ';
            height: calc(100% + 68px);
            left: -18px;
            width: calc(100% + 36px);
            position: absolute;
            top: -28px;
            z-index: -1;
          }
        },

        .tab-paymentMethod {
          .MuiButton-sizeSmall {
            padding: 4px 12px !important;
            max-width: 68px;
          }
        }

        .row-icon {
          flex-direction: column-reverse !important;

          &:before {
            background-image: url(${ThankYouImg});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            content: ' ';
            margin-top: 20px;
            height: 72px;
            width: 180px;
          }

          h4 {
            color: ${colors.success};
            font-size: 22px;
            margin: 0 0 20px !important;
            text-transform: uppercase;
          }

          > div {
            background-color: transparent;
            border: 2px solid ${colors.success};
            height: 140px;
            width: 140px;
            
            &:before {
              background-color: #D7F8EA;
              height: 112px;
              width: 112px;
            }

            svg {
              height: 40px;
              width: 40px;

              path {
                fill: transparent;
              }
            }
          }

          + hr {
            display: none;
          }
        },

        .email-receipt,
        .receipt-loader {
          display: none !important;
        }

        .tab-voided {
          .icon-container {
            background-color: transparent;
            border-width: 4px;
          }
        }

        .empty-tab {
          > div {
            max-width: initial;

            .MuiButtonBase-root:not(.MuiButton-containedSecondary) {
              max-width: 140px;
              padding: 12px 10px;
            }
          }

          p {
            font-size: 1.6rem;
            font-weight: bold;
          }
        },

        .IncodeIOSNotification,
        .IncodeAndroidNotification {
          color: #000000;

          .content {
            h4 {
              margin-bottom: 8px;
            }

            p {
              font-size: 1.4rem !important;
            }
          }

          button {
            font-size: 1.6rem !important;
            padding: 12px 10px;
            height: initial !important;
          }
        },

        .IncodeAllowPermissionsContainer {
          height: 100%;
          min-height: initial !important;

          h1 {
            color: #000000;
            line-height: 1.3;
          }
        },

        .onboardingComplete {
          > div {
            background-color: transparent;
            border: 2px solid ${colors.success};
            color: ${colors.background};
            height: 140px;
            width: 140px;
            
            &:before {
              background-color: #D7F8EA;
              height: 112px;
              width: 112px;
            }

            svg {
              height: 40px;
              width: 40px;

              path {
                fill: transparent;
              }
            }
          }
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
          color: colors.text,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "initial",
        },
        regular: {
          minHeight: "initial",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          textTransform: "uppercase",

          "&.btn_background": {
            fontFamily: fonts.buttonsFont + "!important",
            fontSize: "2.6rem",
            padding: "12px 10px !important",

            "&:before": {
              backgroundImage: `url(${BtnDecoration})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "34px",
              width: "9px",
              position: "absolute",
              left: "8px",
            },
            "&:after": {
              backgroundImage: `url(${BtnDecoration})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "34px",
              width: "9px",
              position: "absolute",
              right: "8px",
            },
          },
          "&:hover": {
            backgroundColor: colors.text,
            color: colors.background,
          },
          "&.cta-button": {
            backgroundColor: colors.secondary,
            color: colors.primary,
          },
          "&.Mui-disabled": {
            backgroundColor: colors.secondaryDisabled + "!important",
            color: colors.text + "!important",
          },
          "&.solid-btn": {
            backgroundColor: "#ffffff",
            color: colors.borderDark,
          },
          ".google-pay-button-container, .apple-pay-button-container": {
            fontSize: "1.7rem",
          },
          "&.expressCheckoutButton": {
            "&:hover": {
              backgroundColor: colors.text,
            },
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: colors.secondary,
            color: colors.primary,
          },
        },
        textPrimary: {
          "&:hover": {
            color: colors.text,
            textDecoration: "underline",
          },
        },
        sizeLarge: {
          fontSize: "1.7rem",
          padding: "15px 10px 13px",
        },
        sizeMedium: {
          fontSize: "1.4rem",
          lineHeight: "2.1rem",
        },
        sizeSmall: {
          fontSize: "1.4rem",
          minWidth: "35px",
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&.cta-button": {
            backgroundColor: colors.secondary,
            color: colors.primary,
            "&:hover": {
              colors: "inherit",
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          color: colors.text,
          fontSize: "2rem",

          "> div ": {
            border: "2px solid " + colors.border,
          },

          "*:not(button)": {
            fontSize: "2rem",
          },

          "&.overwrite label": {
            lineHeight: "2.5rem",
          },

          button: {
            fontSize: "1.6rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.overwrite": {
            backgroundColor: `${colors.paper}`,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: colors.border,
            },
            ".MuiInputAdornment-root": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#000",
          backgroundColor: colors.primary,

          "&.MuiInputAdornment-root p": {
            fontSize: "2rem",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "1px 1px 6px rgb(0 0 0 / 9%)",
          border: "none",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.tip-btn": {
            backgroundColor: "#353739",
            color: "#ffffff !important",
            border: "none !important",
          },

          "&.tip-btn.selected": {
            backgroundColor: "#ffffff",
            color: colors.background + "!important",
            border: "none !important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lighterBackground,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
          fontWeight: "500",

          "&.MuiAlert-standardError": {
            backgroundColor: colors.lighterBackground,
            border: "2px solid " + colors.error,
            color: colors.text,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          ".divider-text": {
            color: colors.text,
          },
        },
      },
    },
  },
});
