import braintree, { Client } from "braintree-web";
import { useCallback, useEffect, useState } from "react";

import AppleIcon from "@mui/icons-material/Apple";
import Button from "@component/Button/Button";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import { applePayLabels } from "vendor/rooamtab/utils/constants";
import { colors } from "@theme/Themes";
import getTabUserAccount from "@utils/getTabUserAccount";
import styled from "styled-components";
import trackGaEvent from "@utils/trackGaEvent";
import updateTabUserAccount from "@utils/updateTabUserAccount";
import { usePlace } from "@context/PlaceContext";

interface ApplePayTokenProps {
  clientInstance: Client | undefined;
  onSuccess: (nonce: string, paymentType: string) => void;
  onError: (error: React.ReactNode) => void;
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>;
}

const ApplePayBtn = styled.div`
  &.apple-pay-button-with-text {
    border-radius: 6px;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: pay;
    width: 100%;
    height: 44px;
    -apple-pay-button-style: ${colors.applePayBtnStyle};
  }
  &.apple-pay-button-with-text > * {
    display: none;
  }
`;

function ApplePayToken({
  clientInstance: client,
  onSuccess,
  onError,
  setError,
}: ApplePayTokenProps) {
  const [applePayInstance, setApplePayInstance] =
    useState<braintree.ApplePay>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasUsername, setHasUsername] = useState<boolean>(false);

  const { place } = usePlace();

  const getUserName = useCallback(async () => {
    const name = await getTabUserAccount();

    setHasUsername(!!name?.first && !!name?.last);
  }, []);

  useEffect(() => {
    void getUserName();
  }, [getUserName]);

  const setTokenizeErrorState = useCallback(
    (session?: ApplePaySession) => {
      setError("tokenizeError");
      setIsLoading(false);

      onError(
        <>
          <strong>Error:</strong> There was an error processing Apple Pay.
          Please try again or contact your server.
        </>
      );

      trackGaEvent("Error: ApplePay performCheckout", {
        description: `PlaceCode: ${place?.code}`,
      });

      session?.completePayment(ApplePaySession.STATUS_FAILURE);
    },
    [onError, place?.code, setError]
  );

  const createApplePay = useCallback(async () => {
    if (client) {
      await braintree.applePay
        .create({ client })
        .then((applePayInstance) => {
          setApplePayInstance(applePayInstance);
        })
        .catch((error) => {
          console.error(
            "ApplePay > Error Creating Braintree Payment instance",
            error
          );
          setTokenizeErrorState();
        });
    }
  }, [client, setTokenizeErrorState]);

  useEffect(() => {
    if (client) {
      createApplePay().catch((error) => console.error(error));
    }
  }, [client, createApplePay]);

  const braintreePaymentRequest = applePayInstance?.createPaymentRequest({
    total: {
      label: applePayLabels.paymentSheet.totalLabel,
      amount: "0.00",
    },
    requiredBillingContactFields: ["name", "postalAddress"],
  });

  const getApplePayToken = useCallback(() => {
    if (braintreePaymentRequest) {
      setError(undefined);
      setIsLoading(true);

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const session = new ApplePaySession(3, {
        ...braintreePaymentRequest,
        total: {
          label: applePayLabels.paymentSheet.totalLabel,
          amount: "0.00",
          type: "pending",
        },
      } as ApplePayJS.ApplePayPaymentRequest);

      session.oncancel = (event: ApplePayJS.Event): void => {
        event.cancelBubble = true;
        setIsLoading(false);

        trackGaEvent("ApplePay onCancel by user", {
          description: `PlaceCode: ${place?.code}`,
        });
      };

      session.onpaymentauthorized = (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        if (event.payment) {
          const firstName = event.payment.billingContact?.givenName;
          const lastName = event.payment.billingContact?.familyName;

          const updateName = async (firstName: string, lastName: string) => {
            await updateTabUserAccount(firstName, lastName);
          };

          applePayInstance
            ?.tokenize({ token: event.payment.token })
            .then((payload) => {
              onSuccess(payload.nonce, "APPLE_PAY");

              if (!hasUsername && firstName && lastName) {
                void updateName(firstName, lastName);
              }

              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            })
            .catch((error) => {
              setTokenizeErrorState();
              console.error("ApplePay > onpaymentauthorized >", error);

              trackGaEvent("Error: ApplePay tokenize", {
                description: `PlaceCode: ${place?.code}`,
              });

              session.completePayment(ApplePaySession.STATUS_FAILURE);
            });
        }
      };

      session.onvalidatemerchant = (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        if (event.validationURL) {
          applePayInstance
            ?.performValidation({
              validationURL: event.validationURL,
              displayName: applePayLabels.validation.displayName,
            })
            .then((merchantSession) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch((error) => {
              setTokenizeErrorState();
              console.error("ApplePay > Error Validating Merchant", error);
              setIsLoading(false);

              trackGaEvent("Error: Validating ApplePay Merchant", {
                description: `PlaceCode: ${place?.code}`,
              });

              try {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
              } catch (error) {
                console.error(
                  "ApplePay > Unable to set failure status for session",
                  session,
                  error
                );
                setTokenizeErrorState();
              }

              try {
                session.abort();
              } catch (abortError) {
                console.error(
                  "ApplePay > Unable to abort session",
                  session,
                  abortError
                );
              }
            });
        } else {
          console.error("ApplePay > Missing Merchant Validation URL", event);
          setIsLoading(false);
          setTokenizeErrorState();

          trackGaEvent("Error: Missing Apple Pay Merchant Validation URL", {
            description: `PlaceCode: ${place?.code}`,
          });
        }
      };

      session.begin();
    }

    trackGaEvent("Clicked ApplePay Button", {
      description: `PlaceCode: ${place?.code}`,
    });
  }, [applePayInstance, braintreePaymentRequest, hasUsername, onSuccess, place?.code, setError, setTokenizeErrorState]);

  if (isLoading) {
    return (
      <Button
        ariaLabel="Connecting Apple Pay"
        className="expressCheckoutBtn"
        isLoading={isLoading}
        size="medium"
      />
    );
  }

  return (
    <ApplePayBtn
      className="apple-pay-button-with-text"
      onClick={getApplePayToken}
    />
  );
}

export default ApplePayToken;
