import braintree, { Client } from "braintree-web";
import { useCallback, useEffect, useMemo, useState } from "react";

import Button from "@component/Button/Button";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import { colors } from "@theme/Themes";
import getTabUserAccount from "@utils/getTabUserAccount";
import styled from "styled-components";
import trackGaEvent from "@utils/trackGaEvent";
import updateTabUserAccount from "@utils/updateTabUserAccount";
import { usePlace } from "@context/PlaceContext";

interface GooglePayTokenProps {
  clientInstance: Client | undefined;
  onSuccess: (nonce: string, paymentType: string) => void;
  onError: (error: React.ReactNode) => void;
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>;
}

const environment =
  process.env.NODE_ENV === "production" ? "PRODUCTION" : "TEST";
const googleMerchantId = process.env.REACT_APP_GOOGLE_MERCHANT_ID;

export const GPayBtn = styled.div`
  display: inline-flex;
  height: 44px;

  button {
    border-radius: 6px;
  }
`;

function GooglePayToken({
  clientInstance: client,
  onSuccess,
  onError,
  setError,
}: GooglePayTokenProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientReady, setClientReady] = useState<boolean>(false);
  const [supportsGooglePay, setSupportsGooglePay] = useState<boolean>(false);
  const [googlePayInstance, setGooglePayInstance] = useState<
    braintree.GooglePayment | undefined
  >(undefined);
  const [hasUsername, setHasUsername] = useState<boolean>(false);
  const [gPayBtnCreated, setGPayBtnCreated] = useState<boolean>(false);

  const { place } = usePlace();

  const paymentsClient = useMemo(
    () =>
      new google.payments.api.PaymentsClient({
        environment,
      }),
    []
  );

  const getUserName = useCallback(async () => {
    const name = await getTabUserAccount();

    setHasUsername(!!name?.first && !!name?.last);
  }, []);

  useEffect(() => {
    void getUserName();
  }, [getUserName]);

  const setTokenizeErrorState = useCallback(
    (error: any) => {
      if (error?.statusCode === "CANCELED") {
        return;
      }

      setError("tokenizeError");
      setIsLoading(false);

      onError(
        <>
          <strong>Error:</strong> There was an error processing Google Pay.
          Please try again or contact your server.
        </>
      );

      trackGaEvent("Error: Getting GooglePay Token", {
        description: `PlaceCode: ${place?.code}`,
      });
    },
    [onError, place?.code, setError]
  );

  const createGooglePayment = useCallback(async () => {
    await braintree.googlePayment
      .create({
        client,
        googlePayVersion: 2,
        googleMerchantId,
      })
      .then(async (googlePaymentInstance) => {
        setGooglePayInstance(googlePaymentInstance);
        setClientReady(true);

        return await paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: (
            await googlePaymentInstance.createPaymentDataRequest()
          ).allowedPaymentMethods,
          existingPaymentMethodRequired: true,
        });
      })
      .then(function (isReadyToPay) {
        const { result } = isReadyToPay;

        if (result) {
          setSupportsGooglePay(result);
        }
      })
      .catch((err) => {
        console.error(
          "GooglePay > Error creating Braintree Payment instance",
          err
        );
        setTokenizeErrorState(err);
      });
  }, [client, paymentsClient, setTokenizeErrorState]);

  useEffect(() => {
    if (!clientReady && googleMerchantId && client) {
      createGooglePayment().catch((error) => console.error(error));
    }
  }, [client, clientReady, createGooglePayment]);

  const getGooglePayToken = useCallback(async () => {
    setIsLoading(true);
    const braintreePaymentRequest =
      await googlePayInstance?.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: "USD",
          totalPriceStatus: "ESTIMATED",
          totalPrice: "0.00",
        },
      });

    const cardPaymentMethod = braintreePaymentRequest?.allowedPaymentMethods[0];

    if (cardPaymentMethod) {
      cardPaymentMethod.parameters.billingAddressRequired = true;

      cardPaymentMethod.parameters.billingAddressParameters = {
        format: "FULL",
        phoneNumberRequired: true,
      };
    }

    if (braintreePaymentRequest && googlePayInstance) {
      paymentsClient
        .loadPaymentData(braintreePaymentRequest)
        .then(async function (paymentData) {
          const fullName: string | undefined =
            paymentData.paymentMethodData.info?.billingAddress?.name;

          if (!hasUsername && fullName) {
            const [firstName, ...lastNameParts] = fullName.split(" ");
            const lastName: string = lastNameParts.join(" ");

            await updateTabUserAccount(firstName, lastName);
          }

          return await googlePayInstance.parseResponse(paymentData);
        })
        .then((payload) => {
          onSuccess(payload.nonce, "GOOGLE_PAY");
        })
        .catch((error) => {
          setTokenizeErrorState(error);
          console.error("GooglePay >", error);

          trackGaEvent("Error: GooglePay loadPaymentData", {
            description: `PlaceCode: ${place?.code}`,
          });
        });
    }
  }, [googlePayInstance, hasUsername, onSuccess, paymentsClient, place?.code, setTokenizeErrorState]);

  const GooglePayButton = paymentsClient.createButton({
    onClick: () => {
      void getGooglePayToken();
    },
    buttonColor: colors.googlePayBtnStyle,
    buttonSizeMode: "fill",
    buttonType: "pay"
  });

  useEffect(() => {
    const gPayBtnContainer = document.getElementById("google-pay-button-container");

    if (gPayBtnContainer && GooglePayButton && !gPayBtnCreated) {
      gPayBtnContainer.appendChild(GooglePayButton);
      setGPayBtnCreated(true);
    }
  }, [GooglePayButton, gPayBtnCreated]);

  if (isLoading) {
    return (
      <Button
        ariaLabel="Processing payment with Google Pay"
        className="gpay-button-loader-container expressCheckoutBtn"
        isLoading={isLoading}
        size="medium"
        text="Pay with Google Pay"
      />
    );
  }

  if (!supportsGooglePay) {
    return <></>;
  }

  return (
    <GPayBtn
      id="google-pay-button-container"
    />
  );
}

export default GooglePayToken;
